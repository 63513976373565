import React, { useState, useEffect } from 'react';
import './ImageAnimation.css'; // Import your CSS file

const ImageAnimation = () => {
  const [animationCompleted, setAnimationCompleted] = useState(false);
  // const [visible, setVisible] = React.useState(false);
        
  useEffect(() => {
    const animationContainer = document.getElementById('image-animation-container');
    // let pop_status = localStorage.getItem('animation_status');
    //       if(!pop_status){
    //         setVisible(true);
    //         localStorage.setItem('animation_status',1);
    //       } 
    if (animationContainer) {

      // Add animation class and event listener after a delay
      setTimeout(() => {
         animationContainer.classList.add('animate-images');
      }, 1000);

      setTimeout(() => {
         setAnimationCompleted(true);
      }, 5000);
 
    }
  }, []);


  return (
    <div
      id="image-animation-container"
      className={`image-animation-container ${animationCompleted ? 'hidden' : ''}`}
    >
      <div className="image left"></div>
      <div className="image right"></div>
    </div>
  );
};

export default ImageAnimation;
