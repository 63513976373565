import './onhandprojects.css';
import React, { useState, useEffect } from 'react';
import ImageGallery from '../../common/ImageGallery/ImageGallery';


export default function Onhandprojects(props) {
  const sel_proj_type= props.projecttype;
  
    let idx=1;
    return (
        <div>
            <section id="onhand_proj" className='container'>
           
           { props.projectdata.map(item => {
                idx++;
                return (idx%2 === 1) ?  
                    <div className='on_hand_projects'>
                    <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div id={item.id + "-" + idx} >
                        <p><span className="blue_txt">{item.proj_name}</span></p>
                        <p>{item.proj_details}</p>
                        <span className="blue_txt">Department: </span><span>{item.dept_name}</span><br/>
                       {(!!item.work_order_date && item.work_order_date!== '') 
                          && <div><span className="blue_txt">Start Date: </span><span>{item.work_order_date}</span><br/>
                        <span className="blue_txt">Project Duration: </span><span>{item.proj_duration}</span><br/></div>}
                        {(!!item.tender_amt_lakhs && parseFloat(item.tender_amt_lakhs)>4000.00) &&
                         <div><span className="yellow_txt em-3">Project Value: Rs. {item.tender_amt_lakhs} lakhs</span><br/></div> }
                      </div>
                    </div> 
                    <div className='col-lg-6 col-md-6 col-sm-12'><ImageGallery  images={item.images}/></div> 
                    </div>
                    <div className="gradient"></div>
                    </div>
                :
                    <div className='on_hand_projects'>
                    <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'> <ImageGallery  images={item.images}/></div> 
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div  id={item.id + "-" + idx} >
                        <p><span className="blue_txt">{item.proj_name}</span></p>
                        <p>{item.proj_details}</p>
                        <span className="blue_txt">Department: </span><span>{item.dept_name}</span><br/>
                       {(!!item.work_order_date && item.work_order_date!== '') 
                          && <div><span className="blue_txt">Start Date: </span><span>{item.work_order_date}</span><br/>
                        <span className="blue_txt">Project Duration: </span><span>{item.proj_duration}</span><br/></div>}
                        {(!!item.tender_amt_lakhs && parseFloat(item.tender_amt_lakhs)>4000.00) &&
                         <div><span className="yellow_txt em-3">Project Value: Rs. {item.tender_amt_lakhs} lakhs</span><br/></div> }
                      </div>
                    </div> 
                    </div>
                    <div className="gradient"></div>
                    </div>
          
            })}
            {/* <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'></div> 
                <div className='col-lg-6 col-md-6 col-sm-12'></div> 
            </div>
            <hr class="gradient"></hr>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'></div> 
                <div className='col-lg-6 col-md-6 col-sm-12'></div> 
            </div> */}
            </section>
        </div>

    )};