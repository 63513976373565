import './UpdateProjectForm.css';
import {React, useState, useEffect, useRef} from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import * as constants from '../common/Constants';

export default function UpdateProjectForm (props)  {
  
  const { register,   reset, 
    setValue, errors, watch } = useForm();
  // const [file, setFile] = useState()
  const selectedProjectType = watch('projectType');

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchInput, setSearchInput] = useState('');

  const [errorImageUpload, setErrorImageUpload] = useState('');
  const [successImageUpload, setSuccessImageUpload] = useState('');
const [errorDataUpload, setErrorDataUpload] = useState('');
const [successDataUpload, setSuccessDataUpload] = useState('');
const [selectedGalleryFile, setSelectedGalleryFile] = useState('');
const [isImagesChanged, setIsImagesChanged] = useState(false);
const [isGalleryImagesChanged, setGalleryIsImagesChanged] = useState(false);

const handleChangeGallery = (event) => {
  setSelectedGalleryFile(event.target.value);
  setGalleryIsImagesChanged(true);
};
  useEffect(() => {
    // Update the filteredProjects whenever the projects prop changes
    if(!!selectedProjectType && !!props.project_data[selectedProjectType]){
    setFilteredProjects(props.project_data[selectedProjectType]);
    }else{
      setFilteredProjects([]);
    }
  }, [props.project_data[selectedProjectType]]);

  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    // Filter projects based on the search input
    const filtered = props.project_data[selectedProjectType].filter(project =>
      !!project.proj_details && project.proj_details.toLowerCase().includes(inputValue)
    );
    setFilteredProjects(filtered);
  };

  const handleClearSelection = () => {
    setSelectedProject(null);
    setFiles([]);
    setSelectedGalleryFile('');
    setSearchInput('');
    setFilteredProjects(props.project_data[selectedProjectType]);
    setIsImagesChanged(false);
    setGalleryIsImagesChanged(false);
  };
 
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

   const [files, setFiles] = useState();
   const [formData, setFormData] = useState({
    proj_name: '', 
    projectType : '',
    proj_details: '',
    dept_name: '',
    tender_amt_lakhs : '',
    work_order_date: '',
    proj_duration: '',
    stipulated_date: '',
    images: []
  });
  const uploadedFiles = [];

  function handleChange(event) {

    if (event.target.files.length > 4) {
      alert("Maximum 4 files can be uploaded. Please select again.");
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      setFiles();
  } else{
    setFiles(event.target.files);
    setIsImagesChanged(true);
  }
  }
  
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClear = () =>{
       // Clear form data after submission
       reset();

  }

  const handleProjectSelection = (proj) => {
    setSelectedProject(proj);
    setFormData(proj);
    setFiles(proj.images);
    setSelectedGalleryFile(proj.gallery_image);
    setIsImagesChanged(false);
    setGalleryIsImagesChanged(false);
  }

  const handleFileUpload = async (file, originalFileName)=>{
    originalFileName = constants.s3_relative_image_folder+originalFileName;
    const fetchPresignedUrl= "https://vnzswd6pdk.execute-api.ap-south-1.amazonaws.com/default/getPresignedURL"
    axios.post(fetchPresignedUrl, {"fileName" : originalFileName}).then((response) => {
      console.log(response.data.body);
      const presignedUrl=response.data.body.url;
      console.log(file);
       fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      })
      .catch((uploadError) => {
        setErrorImageUpload(`Error uploading file: ${uploadError.message}`);
      });
      
    })
    .catch((error) => {
      setErrorImageUpload(`Error fetching presigned URL: ${error.message}`);
    });
      
    
  }
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const formData = new FormData(event.target);
    // Convert FormData to JSON
    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });
    jsonData["id"] = selectedProject.id;

    if(!jsonData.projectType || jsonData.projectType==''){
      alert("Please select Project Type to save project data");
    }

    if(!jsonData.proj_name || jsonData.proj_name==''){
      alert("Please select Project Name to save project data");
    }

    if(!jsonData.proj_details || jsonData.proj_details==''){
      alert("Please select Project Details to save project data");
    }

    else{
      if(!!isImagesChanged){
      if(!!files && files.length>0){
          for (const file of files) {
            let originalFileName;
            if(!!files[0].name){  //upload only if there is change in images
             originalFileName = jsonData["id"] + '_' + file.name; // Use the original file name
            uploadedFiles.push(originalFileName);
            handleFileUpload(file, originalFileName);
            } 
            }
      }
      jsonData["images"] = uploadedFiles;
      if(!!isGalleryImagesChanged){
      jsonData["gallery_image"] = jsonData["id"] + '_' + selectedGalleryFile;
      }else{
        jsonData["gallery_image"] = selectedGalleryFile;
      }
    }else{
      jsonData["images"] = selectedProject.images;
      jsonData["gallery_image"] = selectedProject.gallery_image
    }
      const addProjDataAPI='https://8mxcakpz00.execute-api.ap-south-1.amazonaws.com/default/addProjectToJsonfileOnS3';
      axios.post(addProjDataAPI, jsonData).then((response) => {
        console.log('File uploaded successfully!');
        if (response.status===200) {
          setSuccessDataUpload('Project Data uploaded successfully!');
          setErrorDataUpload('');
          handleClearSelection();
        } else {
          setErrorDataUpload(`Error uploading Project Data: ${response.statusText}`);
          setSuccessDataUpload('');
        }
        props.fetch_proj_callback();
      })
      .catch((uploadError) => {
        setErrorDataUpload(`Error uploading Project Data: ${uploadError.message}`);
        setSuccessDataUpload('');
      }).finally((rep) => {
        setLoading(false);
        setIsImagesChanged(false);
        setGalleryIsImagesChanged(false);
      });
    }    
  } 
   

return (
    <section className='container'>
        {!!loading && 
        <div id="overlay" class="overlay">
        <div class="loader"></div>
      </div>}
    <h1 className='section-title'> Update details/ Upload Pictures</h1>
   <form className='add-form'  onSubmit={handleSubmit} >
   <div className='row'>
         <div className='col-sm-12 col-md-3 col-lg-3'>
       <label><b>Project Type:</b></label>
       </div>
       <div className='col-sm-12 col-md-9 col-lg-9 '>
       <input
         type="radio"
         name="projectType"
         value="Awarded"
         {...register('projectType', { required: true })}
       />
       Awarded
       <input
         type="radio"
         name="projectType"
         value="On-hand"
         {...register('projectType', { required: true })}
       />
       On-hand
       <input
         type="radio"
         name="projectType"
         value="Completed"
         {...register('projectType', { required: true })}
       />
       Completed
       {errors && errors.projectType && <span>This field is required</span>}
       </div>
       </div> 

    <div className='row'>
      <div className='col-sm-12 col-md-6 col-lg-6'>
    <input
        type="text"
        placeholder="Search projects"
        value={searchInput}
        onChange={handleSearch}
      />  <button type="button" onClick={handleClearSelection}>Clear Selection</button>
      <div id="project_list">
        <b>Project List:</b>
        {filteredProjects.map((project, index) => (
          <div key={index}
            onClick={() => handleProjectSelection(project)}
            className={selectedProject === project ? 'selected project' : 'project'}
          >
            {project.proj_details}
          </div>
        ))}
      </div>
    </div>
    <div className='col-sm-12 col-md-6 col-lg-6'>
    <p><span id='error_txt' className='red_text'>{errorImageUpload}</span></p>
      <p><span id='success_txt' className='green_text'></span>{successImageUpload}</p>
      <p><span id='error_txt1' className='red_text'>{errorDataUpload}</span></p>
      <p><span id='success_txt1' className='green_text'>{successDataUpload}</span></p>

    {!!selectedProject &&
    <div>
      {selectedProject && (
        <div>
          <p><b className='red_text'>Selected Project: {selectedProject.proj_details}</b></p>
          {/* Additional details or components for the selected project */}
        </div>
      )}
       <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        
        <label>Project Name/Location:</label><span className='red_text'>[Required]</span>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-9'>
        <input
          type="text"
          name="proj_name"
          value={formData.proj_name}
          onChange={handleChangeInput}
        />
        {errors && errors.proj_name && <span>This field is required</span>}
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Project Details:</label> <span className='red_text'>[Required]</span>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-9'>
        <textarea
          name="proj_details"
          value={formData.proj_details}
          onChange={handleChangeInput}
        />
        {errors && errors.proj_details && <span>This field is required</span>}
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Department Name:</label>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-9'>
        <input
          type="text"  name="dept_name"
          value={formData.dept_name}
          onChange={handleChangeInput}
        />
        {errors && errors.dept_name }
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Tender Amount (In Lakhs):</label>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-9'>
        <input
          type="text"
          name="tender_amt_lakhs"
          value={formData.tender_amt_lakhs}
          onChange={handleChangeInput}
        />
        {errors && errors.tender_amt_lakhs }
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Work Order Date:</label>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-9'>
        <input
          type="text"
          name="work_order_date"
          value={formData.work_order_date}
          onChange={handleChangeInput}
        />
        {errors && errors.work_order_date }
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Project Duration:</label>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-9'>
        <input
          type="text"
          name="proj_duration"
          value={formData.proj_duration}
          onChange={handleChangeInput}
        />
        {errors && errors.proj_duration }
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Stipulated Date:</label>
        </div>
        <div className='col-sm-12 col-md-9 col-lg-9'>
        <input
          type="text"
          name="stipulated_date"
          value={formData.stipulated_date}
          onChange={handleChangeInput}
        />
        {errors && errors.stipulated_date }
      </div>
      </div>

<br></br>
      <div className='row'>
      <p className='txt_note'> Note: Press Ctrl key to select multiple images. Maximum 4 images can be attached to project.</p>
          <div className='col-sm-12 col-md-2 col-lg-2'>
        <label>Project Images:</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <input
         ref={fileInputRef}
          type="file"
          name="images"
          accept="image/*"
          multiple
          onChange={handleChange}
        />
      </div>
      <div className='row'>
      <div className='col-sm-12 col-md-2 col-lg-2'>
        <label>Selected Files:</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 yellow_text'>
        {(!files || files.length==0) && <p>None</p>}
      {!!files && Array.from(files).map(file => {
        return <p>{file.name || file}</p>
      })}
      </div>
      </div>
      </div>
     {!!isImagesChanged && <div className='row'>
      <div className='col-sm-12 col-md-2 col-lg-2'>
        <label>Choose Image for Gallery :</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <select name="gallery_image" value={selectedGalleryFile} onChange={handleChangeGallery}>
        <option value="">Select a file</option>
        {!!files && files.length!=0 && Array.from(files).map((file, index) => (
          <option key={index} value={file.name || file}>{file.name || file}</option>
        ))}
      </select>
      <br/>
      <br/>
      {!!selectedGalleryFile && <p className='yellow_text'>Selected Gallery File: {selectedGalleryFile}</p> }
      </div>
      </div>

        }
      <div className='row'>
    
     <div className='col-sm-12 col-md-6 col-lg-6'>
     <button type="submit" className='submit-button'>Submit</button>
     </div>
     <div className='col-sm-12 col-md-6 col-lg-6'>
     <button  onClick={handleClearSelection}  type="button">Clear</button>
       </div>
     </div>

     </div>
     
    }
     
     </div>
    </div>
      </form>
   </section>
);
}

