import './Contact.css';


export default function Contact() {
    return (
      <div id="contact">
      <div className='container'>
        <section id="contact_hero">
            <h1 className='section-title'>Contact Us</h1>
        </section>
        <div className="row contact">
        <div className="col-lg-6">
          <div className="info-box mb-5 ">
            <i className="bx bx-map"></i>
            <h3>Ahmedabad</h3>
            <p>A-502 Empire Business Hub, Science City Road, Sola,</p>
            <p>Ahmedabad, Gujarat 380060, India</p>
            {/* <p> Mo: 9825070122 [Mr.K.k.atel]  </p>
            <p> Mo: 9825026672 [Mr.Akshay Patel] </p> */}
           <p>Email: ncc_infraspace2015@yahoo.in</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="info-box mb-5">
            <i className="bx bx-map"></i>
            <h3>Mehsana</h3>
            <p>301, 3rd Floor, Orbit Business Hub, Nr.Dena Bank, Radhanpur Road,</p>
            <p>Mehsana-384002 </p>
            <p> Office: 02762-255962 </p>
            {/* <p> Mo: 9825070122 (Mr.K.k.atel)  </p>
            <p> Mo: 9825026672 (Mr.Akshay Patel) </p> */}
              <p>Email: ncc_infraspace2015@yahoo.in</p>
          </div>
        </div>
      </div>
        <div className="row map-box">
        <div className="col-lg-6 mb-4 ">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.657801944179!2d72.5132729258535!3d23.073003943251056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9cb6b351130b%3A0xce418d22916f76bd!2sEmpire%20Business%20Hub%2C%20Sola%2C%20Ahmedabad%2C%20Gujarat%20380060%2C%20India!5e0!3m2!1sen!2sus!4v1708100631602!5m2!1sen!2sus" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
       </div>
        <div className="col-lg-6 mb-4 ">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1827.9583889909554!2d72.37694275057522!3d23.60731742205538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c423328c623df%3A0x95cfab1340bf2d89!2sOrbit%20-%20The%20Business%20Hub!5e0!3m2!1sen!2sus!4v1707858320630!5m2!1sen!2sus" width="100%" height="100%"  style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        </div>
       
      </div>
      </div>
    )
  }
  