// ImageGallery component

import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageGallery.css'; // Import your custom CSS file
import * as constants from '../Constants';

const ImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  useEffect(() => {
    const closeDialogOnOutsideClick = (event) => {
      if (selectedImage !== null && !event.target.closest('.carousel-container')) {
        setSelectedImage(null);
      }
    };

    document.addEventListener('mousedown', closeDialogOnOutsideClick);

    return () => {
      document.removeEventListener('mousedown', closeDialogOnOutsideClick);
    };
  }, [selectedImage]);

  const handleCloseDialog = () => {
    setSelectedImage(null);
  };

  return (
    <div className="image-gallery">
      {!!images && images.length>0 && 
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img
              src={constants.s3_image_folder+image}
              alt={`Image ${index + 1}`}
              onClick={() => setSelectedImage(index)}
            />
          </div>
        ))}
      </Slider>
     }
      {selectedImage !== null && (
        <div className="overlay">
          <div className="carousel-container">
            <Slider {...settings} initialSlide={selectedImage}>
              {images.map((image, index) => (
                <div key={index} className="carousel-item">
                  <img src={constants.s3_image_folder+image} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </Slider>
            
          </div>
          <span className="close-btn" onClick={handleCloseDialog}><i class='bx bx-x-circle'></i></span>
          
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
