import React, { useState, useEffect } from 'react';
import NewProjectForm from './NewProjectForm';
import UpdateProjectForm from './UpdateProjectForm';
import './AdminForm.css'; // Make sure to adjust the path based on your project structure
import * as constants from '../common/Constants';

export default function AdminForm() {
  const [selectedForm, setSelectedForm] = useState('');
  const [projectData, setProjectData] = useState({});
  function onSelectForm(action) {
    setSelectedForm(action);
  }

  useEffect(() => { 
    fetchProjData();
      },[]); 

  const fetchProjData = async() => {
        try {
          const response = await fetch(constants.s3_proj_data_json_file_map['On-hand'],{
            method: 'GET',
            headers: {
              'Cache-Control': 'no-cache',
            },}); 
          const data = await response.json();
          setProjectData(prevState => ({ ...prevState, 'On-hand': data }));
          const response2 = await fetch(constants.s3_proj_data_json_file_map['Awarded'],{
            method: 'GET',
            headers: {
              'Cache-Control': 'no-cache',
            },}); 
          const data2 = await response2.json();
          setProjectData(prevState => ({ ...prevState, 'Awarded': data2 }));
          const response3 = await fetch(constants.s3_proj_data_json_file_map['Completed'],{
            method: 'GET',
            headers: {
              'Cache-Control': 'no-cache',
            },}); 
          const data3 = await response3.json();
          setProjectData(prevState => ({ ...prevState, 'Completed': data3 }));

        }catch (error) {
          console.error('Error fetching data:', error);
        }
      };

  return (
    <section id="admin-form" className='container'>
      <div className='admin-form-tabs'>
        <div
          className={`admin-form-link`}
          onClick={() => onSelectForm('New')}>
          <a  className={`admin-form-link ${selectedForm === 'New' ? 'active' : ''}`}
           >Add New Project </a>
        </div>
        <div
          className={`admin-form-link`}
          onClick={() => onSelectForm('Upload')}>
          <a  className={`admin-form-link ${selectedForm === 'Upload' ? 'active' : ''}`} >
          Update details for existing project</a>
        </div>
      </div>
    <div className='form-section'>
    {selectedForm === 'New' && <NewProjectForm project_data={projectData} fetch_proj_callback={fetchProjData}/>}
      {selectedForm === 'Upload' && <UpdateProjectForm project_data={projectData} fetch_proj_callback={fetchProjData}/>}
      </div>
    </section>
  );
}
