import './Projectsubmenu.css';
import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

export default function Projectsubmenu(props) {
    
    const sel_proj_type= props.projecttype;
    const [expanded, setExpanded] = useState(false);

    const onTrigger = (event) => {
        setExpanded(false);
        props.parentCallback();
    };
    useEffect(() => {
        // Remove the CSS class when the component has mounted
        const element = document.getElementById('all_proj_link');
        if (!!element && !!sel_proj_type && sel_proj_type!='default' ) {
          element.classList.remove('active');
        }
      }, []);

    return (
      <div id="project_submenu">
       
       <Navbar expand="lg"  id="sub-header" expanded={expanded}>
      
        <Navbar.Toggle aria-controls="basic-navbar-subnav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-subnav" >
          <Nav className="nav-list">
            <NavLink to="/project-services/all"  id="all_proj_link" activeclassname="active" onClick={() => setExpanded(false)}>All Projects</NavLink>
            <NavLink to="/project-services/on-going" onClick={onTrigger} activeclassname="active">On-Hand</NavLink>         
            <NavLink to="/project-services/awarded" onClick={onTrigger} activeclassname="active" >Awarded</NavLink>
            <NavLink to="/project-services/completed" onClick={onTrigger} activeclassname="active">Completed</NavLink>
            
            {/* <NavLink to="/project-services/SG-highway" activeclassname="active">S. G. Highway</NavLink>
            <NavLink to="/project-services/gandhi-ashram" activeclassname="active">Prestigious Projects</NavLink> */}
          </Nav>
        </Navbar.Collapse>
    
    </Navbar>
   
       
{/* 
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ">
            <li className="nav-item active">
                <a className="nav-link" href="/project-services">All</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/project-services/roads-highways">Roads & Highways</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/project-services/bridges">Bridges</a>
            </li>
            <li className="nav-item">
                <a className="nav-link " href="/project-services/awarded">Awarded</a>
            </li>
            <li className="nav-item">
                <a className="nav-link " href="/project-services/completed">Completed</a>
            </li>
            <li className="nav-item">
                <a className="nav-link " href="/project-services/on-going">On Going</a>
            </li>
            </ul>
        </div>
        </nav> */}
      </div>
    )
  }
  