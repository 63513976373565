import './ProjectGallary.css';
import React, { useState, useEffect, useRef} from 'react';
import * as constants from '../common/Constants';

export default function ProjectGallary(props) {
  const [galleryData, setGalleryData] = useState([]);
   
  useEffect(() => { 
      
      fetchGalleryData();
  
      }, []); 
      const fetchGalleryData = async() => {
        try {
          const response = await fetch(constants.s3_proj_data_json_file_map['Gallery'],
            {
              method: 'GET',
              headers: {
                'Cache-Control': 'no-cache',
              },}); 
          const galleryData = await response.json();
          setGalleryData(galleryData);
        }catch (error) {
          console.error('Error fetching data:', error);
        }
      };


    return (
        <section id="project_gallary" className='container projects'>
        <div className='my-3 projects_text'>  
        <p>Welcome to NCC Infraspace's Road Construction Division, where we take pride in overcoming challenges to pave the way for a better and well-connected future. Our commitment to excellence is evident in our handling of challenging projects, especially in hard-to-reach areas. Negotiating rugged terrains and remote locations, we bring innovation and determination to every endeavor. We specialize in the strategic deployment of large machinery and vehicles, ensuring minimal disruption to ongoing traffic during road construction. Our expertise lies not only in building robust infrastructure but also in implementing efficient diversions to maintain the flow of daily life. At NCC Infraspace, we embrace challenging projects as opportunities to showcase our technical prowess and dedication to quality road construction.</p>
            </div>
 
            {!!galleryData && galleryData.length>0 && galleryData.map((item) => (
  <div className="responsive project">
    <div className="gallery">
    <div className="image-container" >
    {/* <div className="image-container" onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}> */}
        <a target="_blank" href={item.image_url}>
          <img src={constants.s3_image_folder+item.image_url} alt="" width="600" height="400" />
        </a>
        {/* {hoveredIndex !== index && ( */}
          <div className="hover-bar">
            {item.image_title}
          </div>
        {/* )} */}
      </div>
    </div>
  </div>
))}
 
         </section>
    )}
