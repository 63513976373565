import './Projects.css';
import React, { useState, useEffect, useRef} from 'react';
import { Outlet } from 'react-router-dom';
import image1  from './images/image-1.jpg';
import image2  from './images/image-2.jpg';
import image3  from './images/image-3.jpg';
import image4  from './images/image-4.jpg';
import image6  from './images/image-6.jpg';
import image5  from './images/image-5.jpg';
import Projectsubmenu from './projectsubmenu/Projectsubmenu';
import Onhandprojects from './onhandprojects/onhandprojects';
import * as constants from '../common/Constants';

export default function Projects(props) {

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // Adjust the scroll threshold based on your design
      if (offset > 360) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    const sel_proj_type= props.projecttype;
    const project_types = {
      "Highways" : { Hero_label : "Roads & Highways" , Hero_image : image1 },
      "Bridges" : { Hero_label : "Bridges", Hero_image : image2 },
      "Awarded" : { Hero_label : "Awarded Projects", Hero_image : image3 },
      "Completed" : { Hero_label : "Completed Projects", Hero_image : image4 },
      "On-hand" : { Hero_label : "On-hand Projects", Hero_image : image5 },
      "SGHighway" : { Hero_label : "SG Highway", Hero_image : image5 },
      "GandhiAshram" : { Hero_label : "Prestigious Projects", Hero_image : image5 },
      "default" : { Hero_label : "Our Projects", Hero_image : image6 }
    };

      // const [hoveredIndex, setHoveredIndex] = useState(null);

      // const handleMouseEnter = (index) => {
      //   setHoveredIndex(index);
      // };
    
      // const handleMouseLeave = () => {
      //   setHoveredIndex(null);
      // };
    
    const [data, setData] = useState([]);
    const [galleryData, setGalleryData] = useState([]);
    // const [onHandProjData, setonHandProjData] = useState([]);
    // const [awardedProjData, setawardedProjData] = useState([]);
    useEffect(() => { 
      
      if(!!sel_proj_type && sel_proj_type=='default'){
        fetchGalleryData();
        }else{
          fetchProjData();
        }
        }, [sel_proj_type]); 


    // Trigger data update when selProjType changes
    // useEffect(() => {
    //   updateProjData();
    // }, [sel_proj_type, onHandProjData, awardedProjData ]);

    const fetchProjData = async() => {
      if(!!sel_proj_type && sel_proj_type!='default'){
          try {
            const response = await fetch(constants.s3_proj_data_json_file_map[sel_proj_type],
              {
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                  // other headers...
                },}); // Update the path accordingly
            const jsonData = await response.json();
            setData(jsonData);
            // let onGoingProj = [];
            // let awardedProj = [];
            // if(!!jsonData && jsonData!=null){
            //   jsonData.forEach(function(item) {
            //   if(!!item.work_order_date && item.work_order_date.trim()!==""){
            //     onGoingProj.push(item);
            //   }
            //   else{
            //     awardedProj.push(item);
            //   }
          //});
          // }
          // setonHandProjData(onGoingProj);
          // setawardedProjData(awardedProj);
          // updateProjData();
          }catch (error) {
            console.error('Error fetching data:', error);
          }
        }else{
          console.log('Not valid Project type >>'+sel_proj_type);
        }
        };

        const fetchGalleryData = async() => {
          try {
            const response = await fetch(constants.s3_proj_data_json_file_map['Gallery'],
              {
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                },}); 
            const galleryData = await response.json();
            setGalleryData(galleryData);
          }catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    // const updateProjData = () => {
    //   if (sel_proj_type === 'On-hand') {
    //     setData(onHandProjData);
    //   } else if (sel_proj_type === 'Awarded') {
    //     setData(awardedProjData);
    //   }
    // }
    return (
      <div id="projects">
        <div className='container'>
        <section id="projects_hero" style={{ backgroundImage:`url(${project_types[sel_proj_type].Hero_image})` }}>
            <h1 className='section-title'> {project_types[sel_proj_type].Hero_label }</h1>   
        </section>
       <section  className={`projects-submenu ${isSticky ? 'sticky' : ''}`}>
       <Projectsubmenu projecttype={sel_proj_type} parentCallback={fetchProjData} />
       </section>
        </div>

       {(sel_proj_type==='On-hand' || sel_proj_type==='Awarded' || sel_proj_type==='Completed') &&
       <Onhandprojects projecttype={sel_proj_type}  projectdata={data} /> }

      {(sel_proj_type==='Highways' || sel_proj_type==='Bridges' || sel_proj_type==='default') &&
       <section id="project_gallary" className='container projects'>
       <div className='my-3 projects_text'>  
       <p>Welcome to NCC Infraspace's Road Construction Division, where we take pride in overcoming challenges to pave the way for a better and well-connected future. Our commitment to excellence is evident in our handling of challenging projects, especially in hard-to-reach areas. Negotiating rugged terrains and remote locations, we bring innovation and determination to every endeavor. We specialize in the strategic deployment of large machinery and vehicles, ensuring minimal disruption to ongoing traffic during road construction. Our expertise lies not only in building robust infrastructure but also in implementing efficient diversions to maintain the flow of daily life. At NCC Infraspace, we embrace challenging projects as opportunities to showcase our technical prowess and dedication to quality road construction.</p>
           </div>

       
         {!!galleryData && galleryData.length>0 && galleryData.map((item) => (
  <div className="responsive project">
    <div className="gallery">
    <div className="image-container" >
    {/* <div className="image-container" onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}> */}
        <a target="_blank" href={item.image_url}>
          <img src={constants.s3_image_folder+item.image_url} alt="" width="600" height="400" />
        </a>
        {/* {hoveredIndex !== index && ( */}
          <div className="hover-bar">
            {item.image_title}
          </div>
        {/* )} */}
      </div>
    </div>
  </div>
))}

        </section>
        }

        <Outlet/>
      </div>
    )
  }
  