import './Nomatch.css';


export default function NoMatch() {
    return (
      <div id="Nomatch">
        <section id="Nomatch">
            <h1 className='section-title'>Page not found</h1>
        </section>
        
      </div>
    )
  }
  