
import React, { useState } from 'react';
import './Home.css';
import SlickSlider from "./slickslider/SlickSlider";
import ClientSlider from './clientslider/Clientslider';
import MilestoneSlider from './milestoneslider/MilestoneSlider';
import image_SG_Highway from './images/SGH-image-6.webp';
import image_Sabarmati_aashram from './images/Sabarmati-Ashram-2.webp';
// import image1 from './images/Bridge.jpeg';
// import image2 from './images/Highway.jpeg';
// import image3 from './images/fly-over-2.jpeg';
// import image4 from './images/Highway-2.jpg';
// import image5 from './images/fly-over.jpeg';

import icon_quality from './icons/_0000_badge.webp';
import icon_integrity from './icons/honesty.png';
import icon_experience from './icons/certificate.png';
import icon_social from './icons/2.png';
import icon_client from './icons/honesty.png';
import icon_team from './icons/_0003_teamwork.webp';

export default function Home() {

    const philosophy_virtues = [
        {
            title: "Quality",
            icon: icon_quality,
            tag_line: "Setting New Standards, Delivering Excellence Every Time.",
            details: "We strive for excellence in all aspects of our work. From the inception of a project to its completion, our focus on quality and precision is unwavering. By consistently delivering high-quality results, we aim to set new standards and exceed expectations."
        },
        {
            title: "Integrity",
            icon: icon_integrity,
            tag_line: "Upholding Values, Building Trust, and Fostering Integrity.",
            details: "Integrity is the foundation of our business. We uphold the highest ethical standards in our interactions with clients, partners, and the community. Honesty, transparency, and accountability are ingrained in our culture, fostering trust and long-lasting relationships."
        },
        {
            title: "Seasoned",
            icon: icon_experience,
            tag_line: "Decades of Mastery, Navigating Challenges with Seasoned Expertise",
            details: "We value the wealth of experience and seasoned expertise within our team. Our seasoned team members bring wisdom, mentorship, and a deep understanding of the industry to every endeavor with their accumulated knowledge and skills."
        },
        {
            title: "Social Services",
            icon: icon_social,
            tag_line: "Building Beyond Structures, Investing in Community Well-being.",
            details: "We recognize the impact our projects have on society. Committed to giving back, we actively participate in social and educational initiatives. Our involvement with various institutions reflects our dedication to the betterment of the communities we serve."
        },
        {
            title: "Client-Centric ",
            icon:icon_client,
            tag_line: "Your Vision, Our Priority, Enduring Partnerships.",
            details: "Understanding and exceeding client expectations is at the core of our philosophy. We listen attentively, adapt to evolving needs, and prioritize client satisfaction. Our client-centric approach builds enduring partnerships based on mutual respect and shared success."
        },
        {
            title: "Teamwork",
            icon: icon_team,
            tag_line: "United for Success, Diverse Strengths, One Vision.",
            details: "Our success is a result of collaborative efforts. We value the diversity and expertise of our team members, fostering an inclusive environment where everyone's contributions are acknowledged and celebrated. Together, we overcome challenges and achieve milestones."
        }


    ];
    const [isExpandedExpertise, setExpandedExpertise] = useState(false);
    const handleReadMoreButtonClick = () => {
        setExpandedExpertise(!isExpandedExpertise);
      };
    
    return (
        <div id='home'>
        <section id="hero" className="container">
            <SlickSlider/>
            <div className="news_tag_line_main">
								<div className="font_18 new_line">
									<span className="news_border_left">NCC</span>
								</div>
								<div className="marquee_line"> 
									<marquee className="font_16">
                                    NCC news will be displayed here. NCC news will be displayed here. NCC news will be displayed here. NCC news will be displayed here. 
                                    </marquee>
									
								</div>
			</div>
        </section>

        <section id="our_expertise" className='container'>
            <div className='row'>
                <div id="expertise-text-block" className='col-sm-12 col-md-4 col-lg-4' >
                <div className='section-title'> <h1>Our Expertise </h1></div>
                   </div>
                <div className='col-sm-12 col-md-8 col-lg-8' data-aos='fade-up'>
                {/* <div id="expertise-details" className={`expandable-div ${isExpandedExpertise ? 'expanded' : ''}`}> */}
                <div id="expertise-details" className='expanded'>
                <p>NCC Infraspace is a respected government-approved contractor, rated as "AA Class" with a special focus on Road and Bridge projects. We've been active in this field since 1987, successfully completing projects with high quality and staying within budget.</p>
                <p>Our company has worked on various significant projects for the Government, Semi-Government, and Private sectors, including PMGSY road projects, NABARD Road projects, N.H. projects, EPC projects, Hudco projects, Major Earthwork projects, GMC smart city Project in Gandhinagar, GUDC MSWM projects, and building projects in SSPA & DPEP departments across different districts of Gujarat.</p>
                {/* {isExpandedExpertise && ( */}
                    <div>
                    <p>Our commitment to quality remains strong, whether it's a public or private sector project. We also understand the financial aspects affecting national budgets and follow Supreme Court guidelines for MSWM projects in GUDC.</p>
                    <p>Successfully completing challenging projects in Gujarat, Madhya Pradesh, Uttar Pradesh, and Rajasthan, our team includes qualified engineers and technical staff dedicated to project execution. Quality is a top priority for us at every stage of the project.</p>
                    <p>With extensive experience in various projects, our management ensures that we complete projects within the specified budget and timeframe.</p>
                    {/* <p>Check out our <a href="/project-services"> projects in details</a>. </p> */}
                    </div>
                {/* )} */}
                </div>
          {/* <button onClick={handleReadMoreButtonClick} id="btn-readmore">{isExpandedExpertise ? 'Read less' : 'Read more'}</button> */}
             
                </div>
                {/* <div className='col-sm-12 col-md-8 col-lg-8'>
                    <div className='row photo_gallary'>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                        <img src={image1}/>
                        <img src={image2}/>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                        <img src={image3}/>
                        <img src={image4}/>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-4' >
                        <img src={image5} style={{height:"100%"}}/>
                        </div>
                    </div>
                </div> */}
            </div>
            
        </section>

        <section id="our_philosophy" className='container'>
        <div className="overlay"></div>
            <div className='row'>
                <div className='col-sm-12 col-md-4 col-lg-4 philosophy_title_wrapper'>
                <div className='section-title'> <h1>Our Philosophy </h1>
                </div>
                <p>At NCC Infraspace, our philosophy is rooted in a set of core values and virtues that guide our every endeavor. These principles reflect our commitment to excellence, integrity, and social responsibility.</p></div>
                
                <div className='col-sm-12 col-md-8 col-lg-8'>
                    <div className='row philosophy_container'>
                   
                   { philosophy_virtues.map(item => {
                      return  <div className='col-sm-12 col-md-6 col-lg-4 philosophy_block' >
                        <div><span ><img style={{display:"inline"}} src={item.icon} /> &nbsp;<h6 style={{display:"inline"}}>{item.title}</h6></span>
                        {/* <br></br><span><strong>{item.tag_line}</strong></span> */}
                        <p> {item.details}</p></div>
                        </div>
                   })}
               
                </div>
                </div>
            </div>
        </section>

      <section className='container'><div className='section-title'><h1>Our Clients</h1></div></section>
        
        <section id="our_clients" className='container'>

            <ClientSlider/>
		
        </section>

        <section id="milestones" className='container'>
        <div className="row">
            <div className='col-sm-12 col-md-4 col-lg-4'>
            <div className='section-title'><h1>Milestone</h1></div>
                </div>

                <div className='col-sm-12 col-md-8 col-lg-8'>
                    <MilestoneSlider/>
                </div>
        </div>
        </section>

        <section id="prestigious_projects" className='container'>
        <div className='section-title'><h1>Prestigious Projects</h1></div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <div className='proj_image'>
                <img src={image_SG_Highway} alt="S.G.Highway"/>
            </div>
            <div className='proj_details'>
            <h3>S. G. Highway</h3>
            <p>Client: N.H Department Government Of Gujarat</p>
            <p> Development of Childa-Gandhinagar-Sarkhej of NH-147 (Old NIH-8C) rom kn 1/0 to 44/920 in the State of Gujarat to six-lane with paved shoulders with both side continuous senice road on EPC mode. (Job No. 147/GJ/2017-18/607) Package- km 16/3650 to 31/300 Letter of Award (10A) 109
            </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
          <div className='proj_image'>
                <img src={image_Sabarmati_aashram} alt="S.G.Highway"/>
            </div>
            <div className='proj_details'>
            <h3>Sabarmati Aashram Redevelopment</h3>
            <p>Client:- Government Of Gujarat</p>
            <p> Sabarmati Aashram Redevelopment </p>
            </div>
          </div>
          </div>
        
        </section>

        <section id="our_presence" className='container'>
        <div className='section-title'><h1>Our Presence</h1></div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <div className='map_wrapper'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.657801944179!2d72.5132729258535!3d23.073003943251056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9cb6b351130b%3A0xce418d22916f76bd!2sEmpire%20Business%20Hub%2C%20Sola%2C%20Ahmedabad%2C%20Gujarat%20380060%2C%20India!5e0!3m2!1sen!2sus!4v1708100631602!5m2!1sen!2sus" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className='address_wrapper'>
            <h3>Ahmedabad</h3>
            A-502 Empire Business Hub, Science City Road, Sola,<br/>
            Ahmedabad, Gujarat 380060, India<br/>
            {/* Mobile: 9825070122 (Mr.K.k.atel)  <br/>
            Mobile: 9825026672 (Mr.Akshay Patel) <br/> */}
           Email: ncc_infraspace2015@yahoo.in<br/>
            <span><a target="_blank" href='https://www.google.com/maps/dir//Empire+Business+Hub+Sola+Ahmedabad,+Gujarat+380060+India/@23.0730039,72.5132729,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395e9cb6b351130b:0xce418d22916f76bd!2m2!1d72.5132729!2d23.0730039?entry=ttu'>Navigate</a></span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
          <div className='map_wrapper'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1827.9583889909554!2d72.37694275057522!3d23.60731742205538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c423328c623df%3A0x95cfab1340bf2d89!2sOrbit%20-%20The%20Business%20Hub!5e0!3m2!1sen!2sus!4v1707858320630!5m2!1sen!2sus" width="100%" height="100%"  style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className='address_wrapper'>
          <h3>Mehsana</h3>
            301, 3rd Floor, Orbit Business Hub, Nr.Dena Bank, Radhanpur Road,<br/>
            Mehsana-384002 <br/>
            Office: 02762-255962 <br/>
            {/* Mobile: 9825070122 (Mr.K.k.atel)  <br/>
            Mobile: 9825026672 (Mr.Akshay Patel) <br/> */}
            Email: ncc_infraspace2015@yahoo.in<br/>
            <span><a target="_blank" href='https://www.google.com/maps/dir//Orbit+-+The+Business+Hub+Orbit+business+hub+,+Nr.+Dena+Bank+,+Randhanpur+Road+,+Mehsana-02+Radhanpur+Rd,+Radhanpur+Road+Mehsana,+Gujarat+384002,+India/@23.6073157,72.3778462,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395c423328c623df:0x95cfab1340bf2d89!2m2!1d72.3778462!2d23.6073157?entry=ttu'>Navigate</a></span>
            </div>
          </div>
          </div>
        
        </section>

        </div>


    )
}