
import React,{useEffect} from "react";
import { BrowserRouter , Route, Routes } from 'react-router-dom';
import Home from "./home/Home";
import Menubar from "./menubar/Menubar";
import Footer from "./footer/Footer";

import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Ourcompany from "./ourcompany/Ourcompany";
import Projects from "./projects/Projects";
import Awards from "./awards/Awards";
import Contact from "./contact/Contact";
import NoMatch from "./nomatch/Nomatch";
import AdminForm from "./admin/AdminForm";
import ImageAnimation from "./image_animation/ImageAnimation";
import ProjectGallary from "./projectgallary/ProjectGallary";

function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <BrowserRouter>
    <div className="App">
    <ImageAnimation />
       <Menubar/>
      <div className="main_wrapper">
          <Routes>
          <Route index element={<Home />} />
          <Route exact path="/" element={<Home />} />
          <Route  path="/our-company" element={<Ourcompany />} />
            <Route path="/project-services/all" element={<Projects projecttype="default" />} />
            <Route path="/project-services/roads-highways" element={<Projects projecttype="Highways" />} />
            <Route path="/project-services/bridges" element={<Projects projecttype="Bridges" />} />
            <Route path="/project-services/awarded" element={<Projects projecttype="Awarded" />} />
            <Route path="/project-services/completed" element={<Projects projecttype="Completed" />} />
            <Route path="/project-services/on-going" element={<Projects projecttype="On-hand" />} />
            {/* <Route path="/project-services/gandhi-ashram" element={<Projects projecttype="GandhiAshram" />} /> */}
            {/* <Route path="/SG-highway" element={<SGHighway />} />  */}
            <Route exact path="/gallery"  element={<ProjectGallary/>} />
            <Route exact path="/project-services"  element={<Projects projecttype="default" />} />
            <Route exact path="/admin/project"  element={<AdminForm />} />
            {/* <Route path="/awards" element={<Awards />}  /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
      </div>
  <Footer/>
</div>
</BrowserRouter>
  );
}

export default App;
