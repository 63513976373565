import './Ourcompany.css';
import React, {useEffect, useState} from 'react';

import ClientSlider from '../home/clientslider/Clientslider';
import image_kkpatel from './Mr.K.K.Patel.jpg';
import image_akpatel from './AkshayPatel.jpeg';


export default function Ourcompany() {
  const [showKkPatelDetails, setShowKkPatelDetails] = useState(true);
  const [showAkPatelDetails, setShowAkPatelDetails] = useState(false);
  const [selectedBOD, setSelectedBOD] = useState('KKPatel');
  function displayKKPatelDetails(){
    setShowKkPatelDetails(true);
    setSelectedBOD('KKPatel');
    setShowAkPatelDetails(false);
  }

  function displayAKPatelDetails(){
    setShowKkPatelDetails(false);
    setSelectedBOD('AKPatel');
    setShowAkPatelDetails(true);
  }
 
    return (
      <div id="our_company">
        <section id="our_company_hero" className='container'>
        <div className="overlay"></div>
        <div className="text-container">
          <h1 className='section-title'> Every Milestone is Our Vision</h1>
          <p>Building Excellence Since 1987: Pioneering Construction of Roads, Bridges, and Flyovers for a Brighter Infrastructure Future in Government, Semi-Government, and Private Projects.</p>
        </div>
        </section>

        <section id="contact_hero" className='container'>
        <div className='row'>
        <div className="col-sm-12 col-md-4 col-lg-4 ">
            <h1 className='section-title'> Our Vision</h1>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-8 ">
        <p>Striving for perfection in order to establish a prominent position, by exemplary performance, state of art quality work, thereby providing almost satisfaction to all the stakeholders
        </p></div>
        </div>
        </section>
          <section className='container'>
          <div className='row'>
          <div className="col-sm-12 col-md-4 col-lg-4 ">
            <h1 className='section-title'> Our Company</h1>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-8 ">
          <p>NCC Infraspace is a respected government-approved contractor, rated as "AA Class" with a special focus on Road and Bridge projects. We've been active in this field since 1987, successfully completing projects with high quality and staying within budget.</p>
          <p>Our company has worked on various significant projects for the Government, Semi-Government, and Private sectors, including PMGSY road projects, NABARD Road projects, N.H. projects, EPC projects, Hudco projects, Major Earthwork projects, GMC smart city Project in Gandhinagar, GUDC MSWM projects, and building projects in SSPA & DPEP departments across different districts of Gujarat.
          </p>
          <p>Our commitment to quality remains strong, whether it's a public or private sector project. We also understand the financial aspects affecting national budgets and follow Supreme Court guidelines for MSWM projects in GUDC.
          </p>
          <p>Successfully completing challenging projects in Gujarat, Madhya Pradesh, Uttar Pradesh, and Rajasthan, our team includes qualified engineers and technical staff dedicated to project execution. Quality is a top priority for us at every stage of the project.
          </p>
          <p>With extensive experience in various projects, our management ensures that we complete projects within the specified budget and timeframe.</p>
        
          </div>
          </div>
          </section>
          
        <section className='container'><div className='section-title'><h1>Our Clients</h1></div></section>
        <section id="our_clients" className='container'>
            <ClientSlider/>
        </section>

        <section className='container' id='bod'>

        <h1 className='section-title'>Board Of Directors</h1>
        <br/>
          <div className='row'>
            <div className='col-sm-12 col-md-7 col-lg-5 image_wrapper'>
            <img className={selectedBOD === 'KKPatel' ? "bod_images bod_selected_image" : "bod_images"} src={image_kkpatel} id='KKPatel'
            onMouseEnter={() => displayKKPatelDetails(this)}/>
              <img className={selectedBOD === 'AKPatel' ? "bod_images bod_selected_image" : "bod_images"}  src={image_akpatel} id='AKPatel'
            onMouseEnter={() => displayAKPatelDetails(this)}/>
            </div>
            <div className='col-sm-12 col-md-5 col-lg-7 bod-text-wrapper'>
            {!!showKkPatelDetails &&   <div
            id="k.k.patel-details"
          >
          <p id="bod_label"><b>Mr. K. K. Patel - Founder</b></p>
          <p>Mr. K. K. Patel, the founder of NCC Infraspace, hails from a lineage deeply rooted in social service. Inspired by his father, Mr. Kalidas N Patel, a dedicated farmer and village leader, Mr. K.K. Patel relinquished a prestigious position at ONGC to focus on both his business and social activities. His commitment to societal welfare is evident in his multifaceted contributions. With a distinguished career spanning fifteen years in ONGC Ltd., Mr. K.K. Patel served as a Geologist in exploration and production activities in oil and gas fields, notably in Ahmedabad, Tripura, and Mehsana. Leveraging his extensive expertise, he transitioned to the construction sector, establishing a family business that has thrived over the past 35 years. The company, under his visionary leadership, has executed numerous government projects across Gujarat, Madhya Pradesh, and Rajasthan, showcasing unparalleled dedication and achieving remarkable milestones. Mr. K.K. Patel's tireless efforts and the collective hard work of his team have propelled the company to the forefront, earning it a prominent position as one of the leading construction companies in Gujarat.</p>
          <p>Beyond his entrepreneurial pursuits, Mr. K.K. Patel has demonstrated a deep commitment to social and educational causes, actively engaging with various institutions dedicated to community upliftment. His involvement in numerous social, educational, health, and financial initiatives reflects a holistic approach to contributing to society. Through his multifaceted endeavors, Mr. K.K. Patel has not only excelled in the business arena but has also made a lasting impact on the well-being and progress of the communities he serves.       </p>

          </div>
          }
          {!!showAkPatelDetails && <div
              id="a.k.patel-details"
              className={showAkPatelDetails ? 'show-details' : ''}
            >
          <p id="bod_label"><b>Mr. A. K. Patel - Chairman & Managing Director</b></p>
            <p>Mr. Akshay K. Patel, carrying forward the legacy of his father, Mr. K.K. Patel, has demonstrated unwavering commitment and hard work to elevate the business to new heights. His efforts have contributed to the continued success and growth of NCC Infraspace, making it a prominent player in the construction and infrastructure domain.</p>
          </div>
          }
            </div>
          </div>
          
       
        </section>
        <section id="social_work" className='container'>
        <div className='row'>
        <div className="col-sm-12 col-md-4 col-lg-4 ">
        <h1 className='section-title'>Social Commitment</h1>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-8 ">
          <p>NCC Infraspace, under the guidance of Mr. K.K. Patel, has seamlessly integrated social responsibility into its corporate ethos. The company's commitment to community well-being goes beyond construction projects, reflecting a holistic approach to societal upliftment. Through active participation in various social initiatives, educational programs, and healthcare projects, NCC Infraspace has become a catalyst for positive change. Whether supporting educational institutions with thousands of students or contributing to healthcare facilities such as Lions Hospital in Mehsana, the company remains dedicated to creating a meaningful impact in the regions it serves. The exemplary social work of Mr. K.K. Patel and NCC Infraspace is a testament to their unwavering commitment to building not only physical infrastructure but also fostering sustainable and thriving communities.</p>             
            <ul><b>Social Contribution:</b>
                    <li> Vice Chairman The Mehsana Urban Co.op Bank Ltd.Mehsana </li>
                    <li> Vice - Chairman of Gujarat Contractor Association. </li>
                    <li> President Dharti Parivar Mehsana [Social Organization of patel Community]</li>
                    <li> Trustee in Umiya Mataji Sansthan Unjha. </li>
                    <li> Trustee and Managing Commitee Member of Vishva Umiya Fondation  </li>
                    <li> Ex-president of Kedvani Mandal, Jay Umiya Masik</li>
            </ul>
            <ul>
            <b>Education Sector: </b>
            <li> Managing trustee of Sardar Vidhyabhavan Trust ,having Arts,Science, Commerce Law ,B.Ed,M.Ed Collages with a strength of 8000 Students </li>
            <li>One of the Pioneers, Trustee, Ex-Borad of governer at Ganapat Universty</li>
            </ul>
            <ul>
            <b>Health Sector: </b>
            <li>Trutee of Lions Hospital, Mehsana, Gujarat </li>
            </ul>
        </div>
        </div>
        </section>

        

        </div>
    )
  }
  