import {React, useState, useRef} from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import './NewProjectForm.css';
import * as constants from '../common/Constants';

const NewProjectForm = (props) => {
  const {
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
   const [files, setFiles] = useState();
  const uploadedFiles = [];

  const [errorImageUpload, setErrorImageUpload] = useState('');
  const [successImageUpload, setSuccessImageUpload] = useState('');
const [errorDataUpload, setErrorDataUpload] = useState('');
const [successDataUpload, setSuccessDataUpload] = useState('');
const [selectedGalleryFile, setSelectedGalleryFile] = useState('');

const handleChangeGallery = (event) => {
  setSelectedGalleryFile(event.target.value);
};
  function handleChange(event) {
    if (event.target.files.length > 4) {
      alert("Maximum 4 files can be uploaded. Please select again.");
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      setFiles();
  } else{
    setFiles(event.target.files);
  }
  }
  
  const handleClear = () =>{
       // Clear form data after submission
       reset();
  }

  const handleFileUpload = async (file, originalFileName)=>{
    originalFileName = constants.s3_relative_image_folder+originalFileName;
    const fetchPresignedUrl= "https://vnzswd6pdk.execute-api.ap-south-1.amazonaws.com/default/getPresignedURL"
    axios.post(fetchPresignedUrl, {"fileName" : originalFileName}).then((response) => {
      console.log(response.data.body);
      const presignedUrl=response.data.body.url;
      console.log(file);
       fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      })
      .catch((uploadError) => {
        setErrorImageUpload(`Error uploading file: ${uploadError.message}`);
      });
      
    })
    .catch((error) => {
      setErrorImageUpload(`Error fetching presigned URL: ${error.message}`);
    });
    
  }
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const formData = new FormData(event.target);
    // Convert FormData to JSON
    const jsonData = {};
    formData.forEach((value, key) => {
      jsonData[key] = value;
    });
    jsonData["id"] = Date.now().toString(36);

    if(!jsonData.projectType || jsonData.projectType==''){
      alert("Please select Project Type to save project data");
    }

    if(!jsonData.proj_name || jsonData.proj_name==''){
      alert("Please select Project Name to save project data");
    }

    if(!jsonData.proj_details || jsonData.proj_details==''){
      alert("Please select Project Details to save project data");
    }

    else{
      if(!!files && files.length>0){
      for (const file of files) {
        const originalFileName = jsonData["id"] + '_' + file.name; // Use the original file name
        uploadedFiles.push(originalFileName);
        handleFileUpload(file, originalFileName);
        }
      }
      jsonData["images"] = uploadedFiles;
      jsonData["gallery_image"] = jsonData["id"] + '_' + selectedGalleryFile;

      const addProjDataAPI='https://8mxcakpz00.execute-api.ap-south-1.amazonaws.com/default/addProjectToJsonfileOnS3';
      axios.post(addProjDataAPI, jsonData).then((response) => {
        console.log('File uploaded successfully!');
        if (response.status===200) {
          setSuccessDataUpload('Project Data uploaded successfully!');
          setErrorDataUpload('');
          handleClear();
        } else {
          setErrorDataUpload(`Error uploading Project Data: ${response.statusText}`);
          setSuccessDataUpload('');
        }
        props.fetch_proj_callback();
      })
      .catch((uploadError) => {
        setErrorDataUpload(`Error uploading Project Data: ${uploadError.message}`);
        setSuccessDataUpload('');
      }).finally((rep) => {
          setLoading(false);
      }); 
    }    
  } 
    
  //   let jsonfilename = '';
  //   if(!!jsonData.projectType && jsonData.projectType!=''){
  //     jsonfilename = json_file_location + jsonData.projectType+'-proj-data.json';
  //   }
  //   // Now you have jsonData in the form of a JavaScript object
  //   console.log('JSON data:', jsonData);
  //   if(!!props.project_data && !!jsonData.projectType)
  //   {
  //       props.project_data[jsonData.projectType].push(jsonData);
  //   } else if(!!jsonData.projectType){
  //     props.project_data[jsonData.projectType] = jsonData;
  //   }else{
  //     alert("project type is not defined!! select Project type");
  //   }
  //  const fetchPresignedUrl= "https://vnzswd6pdk.execute-api.ap-south-1.amazonaws.com/default/getPresignedURL"
  //  axios.post(fetchPresignedUrl, {"fileName" : jsonfilename}).then((response) => {
  //    console.log(response.data.body);
  //    const presignedUrl=response.data.body.url;
  //      fetch(presignedUrl, {
  //       method: 'PUT',
  //       body: JSON.stringify(jsonData),
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });

  return (
    <section className='container'>
       {loading && 
        <div id="admin_overlay" class="admin_overlay">
        <div class="admin_loader"></div>
      </div>}
     <h1 className='section-title'> Add new project</h1>
    <form className='add-form'  onSubmit={handleSubmit} >
      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Project Type:</label> <span className='red_text'>[Required]</span>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 '>
        <input
          type="radio"
          name="projectType"
          value="Awarded"
          {...register('projectType', { required: true })}
        />
        Awarded
        <input
          type="radio"
          name="projectType"
          value="On-hand"
          {...register('projectType', { required: true })}
        />
        On-hand
        <input
          type="radio"
          name="projectType"
          value="Completed"
          {...register('projectType', { required: true })}
        />
        Completed
        {errors && errors.projectType && <span>This field is required</span>}
        </div>
        </div> 
      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        
        <label>Project Name/Location:</label><span className='red_text'>[Required]</span>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <input
          type="text"
          name="proj_name"
          {...register('proj_name', { required: true, maxLength: 100 })}
        />
        {errors && errors.proj_name && <span>This field is required</span>}
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Project Details:</label> <span className='red_text'>[Required]</span>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <textarea
          name="proj_details"
          {...register('proj_details', { required: true, maxLength: 1000 })}
        />
        {errors && errors.proj_details && <span>This field is required</span>}
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Department Name:</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <input
          type="text"  name="dept_name"
          {...register('dept_name')}
        />
        {errors && errors.dept_name }
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Tender Amount (In Lakhs):</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <input
          type="text"
          name="tender_amt_lakhs"
          {...register('tender_amt_lakhs')}
        />
        {errors && errors.tender_amt_lakhs }
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Work Order Date:</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <input
          type="text"
          name="work_order_date"
          {...register('work_order_date')}
        />
        {errors && errors.work_order_date }
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Project Duration:</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <input
          type="text"
          name="proj_duration"
          {...register('proj_duration')}
        />
        {errors && errors.proj_duration }
      </div>
      </div>

      <div className='row'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
        <label>Stipulated Date:</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <input
          type="text"
          name="stipulated_date"
          {...register('stipulated_date')}
        />
        {errors && errors.stipulated_date }
      </div>
      </div>

<br></br>
      <div className='row'>
      <p className='txt_note'> Note: Press Ctrl key to select multiple images. Maximum 4 images can be attached to project.</p>
          <div className='col-sm-12 col-md-2 col-lg-2'>
        <label>Project Images:</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <input
         ref={fileInputRef}
          type="file"
          name="images"
          accept="image/*"
          multiple
          onChange={handleChange}
        />
      </div>
      <div className='row'>
      <div className='col-sm-12 col-md-2 col-lg-2'>
       
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 yellow_text'>
        <label>Selected Files:</label>
        {(!files || files.length==0) && <p>None</p>}
      {!!files && Array.from(files).map(file => {
        return <p>{file.name}</p>
      })}
      </div>
      </div>
      </div>
      <div className='row'>
      <div className='col-sm-12 col-md-2 col-lg-2'>
        <label>Choose Image for Gallery :</label>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
        <select name="gallery_image" value={selectedGalleryFile} onChange={handleChangeGallery}>
        <option value="">Select a file</option>
        {!!files && files.length!=0 && Array.from(files).map((file) => (
          <option key={file.name} value={file.name}>{file.name}</option>
        ))}
      </select>
      <br/>
      <br/>
      {!!selectedGalleryFile && <p className='yellow_text'>Selected Gallery File: {selectedGalleryFile}</p> }
      </div>
      </div>
      <div className='row'>
      <p><span id='error_txt' className='red_text'>{errorImageUpload}</span></p>
      <p><span id='success_txt' className='green_text'></span>{successImageUpload}</p>
      <p><span id='error_txt1' className='red_text'>{errorDataUpload}</span></p>
      <p><span id='success_txt1' className='green_text'>{successDataUpload}</span></p>
      <div className='col-sm-12 col-md-6 col-lg-6'>
      <button type="submit" className='submit-button'>Submit</button>
      </div>
      <div className='col-sm-12 col-md-6 col-lg-6'>
      <button onClick={handleClear} type="button">Clear</button>
        </div>
      </div>
    </form>
    </section>
  );
};

export default NewProjectForm;
