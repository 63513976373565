import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SlickSlider.css'

// import image1 from './S.G.Highway.webp';
import image2 from './vaishnodevi-circle-2.webp';
import image3 from './S.G.Highway_night.webp';
import image4 from './Sabarmati-Ashram-2.webp';
import image5 from './slider_img1.webp';
import image6 from './SGHighway_2.webp';
// import image7 from './bridge-flyover.webp';
// import image8 from './image-1.webp';
import image1 from './Kheda-Dholka-Road.jpeg';

const SlickSlider = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      variableWidth: false,
      focusOnSelect: true,
      variableHeight:false,
      // dotsClass:"vertical-dots",
      arrows: true,
      
    };
    return (
      <div id="hero-slider">
        <Slider {...settings}>
          <div> 

          <img src={image2}/>
        <div className="overlay overlay-shadow">
          <div className='text-container'>
        <h4 > We are AA class contractor</h4>
          <p>Development of Chiloda-Gandhinagar-Sarkhej of NH-147 (Old NH-8C) from Km. 0/0 to 44/420 in the State of Gujarat to Six-lane with paved shoulders with both side continuous service road on EPC mode. (Job No. 147/GJ/2017-18/607) Package-II Km. 16/350 to 31/300</p>          </div>
        </div>
        </div>

        <div> 

          <img src={image4}/>
          <div className="overlay">
          <div className='text-container'>
            <h4>Gandhi Ashram Precinct</h4>
          <p > Gandhi Ashram Precinct Development at Sardar Patel Stadium Ward in West Zone</p>
          </div>
          </div>
        
        </div>

        <div> 

        <img src={image1}/>
          <div className="overlay">
          <div className='text-container'>
            <h4>Kheda Dholka Road</h4>
            <p > Kheda Dholka Road </p>
          </div>
          </div>

        </div>
            <img src={image3}/>
            <img src={image5}/>
            <img src={image6}/>
        </Slider>
      </div>
    );
  };
  
  export default SlickSlider;
