import React, { useState } from 'react';
import './Footer.css';

export default function Footer() {

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        desc: '',
        email: '',
        pincode: '',
        receiver: 'cpsharma.ho.erp@nccinfraspace.com'
      });
    
      const [formErrors, setFormErrors] = useState({});
      const [isLoading, setIsLoading] = useState(false);
      const [successMessage, setSuccessMessage] = useState('');
      const [errorMessage, setErrorMessage] = useState('');
    
      const validateForm = () => {
        const errors = {};
        // Validation rules here...
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
      };
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
          return;
        }
    
        setIsLoading(true);
        const action = 'https://i9jz38s1o9.execute-api.us-east-1.amazonaws.com/email-beta/contact-us';
        try {
          const response = await fetch(action, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          const data = await response.json();
    
          if (response.status === 200) {
            setIsLoading(false);
            setSuccessMessage('Thank you for contacting us! We will reach back to you shortly.');
          } else {
            setIsLoading(false);
            setErrorMessage(data.body);
          }
           // Clear form data after submission
          setFormData({
            name: '',
            phone: '',
            desc: '',
            email: '',
            pincode: ''
          });
        } catch (error) {
          setIsLoading(false);
          setErrorMessage('An error occurred. Please try again.');
        }
      };
    
    return (
        <div>
        <section id="footer" className='container-fluid'>
          <div className='container'>
            <div className='row'>
                <div className='px-3 col-sm-12 col-md-5 col-lg-5 '>
                    <div className='section-title'>
                    <h1>Mail us</h1>
                    </div>
                <form onSubmit={handleSubmit} className="contact-form">
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                        <input type="text" name="name" className="form-control" id="name" placeholder="Name" value={formData.name}
          onChange={handleInputChange} required/>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                        <input type="text" className="form-control" name="pincode" id="pincode" placeholder="Pin Code" value={formData.pincode}
        onChange={handleInputChange} required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                        <input type="email" className="form-control" name="email" id="email" placeholder="Email" value={formData.email}
        onChange={handleInputChange} required/>
                       <input type="text" className="my-2 form-control" name="phone" id="phone" placeholder="Contact No" value={formData.phone}
        onChange={handleInputChange} required/>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                        <textarea className="form-control" name="desc" id="desc" rows="5" placeholder="Inquiry" value={formData.desc}
        onChange={handleInputChange} required></textarea>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <div className="my-3">
                            {isLoading && <div className="loading">Loading...</div>}
                            {successMessage && <div className="sent-message">{successMessage}</div>}
                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                            </div>
                            <div className="text-center"><button id="btn-submit" type="submit">Send Now</button></div>
                        </div>
                    </div>
                    </form>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6 '>
                <div className='row links-block'>

                    <div className='pl-5 useful-links col-sm-12 col-md-7 col-lg-7 '>
                    <h3> Links </h3>
                    <ul>
                    <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
                    <li><i className="bx bx-chevron-right"></i> <a href="/our-company">Our Company</a></li>
                    <li><i className="bx bx-chevron-right"></i> <a href="/project-services">Projects & Services</a></li>
                    <li><i className="bx bx-chevron-right"></i> <a href="/awards">Awards</a></li>
                    <li><i className="bx bx-chevron-right"></i> <a href="/contact">Contact us</a></li>
                    </ul> 
                    <div className="social-links mt-3  ">
                        <a href="/" className="facebook" title="Check out our facebook page"><i className="bx bxl-facebook-square"></i></a>
                        <a href="/" className="youtube" title="Subscribe to our youtube channel" disabled><i className="bx bxl-youtube"></i></a>
                        <a href="/" className="google-plus" title="Contact us on skype"><i className="bx bxl-skype"></i></a>
                        <a href="/" className="instagram" title="Contact us on instagram" disabled><i className="bx bxl-instagram"></i></a>
                    </div>

                    </div>
                </div>
                </div>
        </div>
        </div>
        </section>
        <section id="copyrights">
            All rights are reserved by NCC.
        </section>
        </div>
    )
}