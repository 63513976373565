import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ClientSlider.css';

import image1 from './Govt_of_india.png';
import image2 from './PMGSY-Pradhanmantri-Gram-Sadak-Yojana.png';
import image3 from './NABARD-logo.png';
import image4 from './NHAI-logo.jpeg';
import image5 from './adani-logo.png';
import image6 from './Gandhinagar_municipal_corp.png';
import image7 from './gift-city-logo.png';
import image8 from './Guj_Road_and_building_dept.jpeg';
import image9 from './MORTH.png';
import image10 from './Ahmedabad_Development_Authority_Logo.svg.png';
import image11 from './Amdavad_Municipal_Corporation_logo.png';
import image12 from './MP-PWD-Division.png';
import image13 from './UP-PWD-Division.jpeg';


const ClientSlider = () => {
    const settings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 3000,
        pauseOnHover: false,
        cssEase: 'linear',
        variableWidth: true,
        arrows: false,
        rows:1,
        adaptiveHeight: true,
        responsive: [{
          breakpoint: 768,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 520,
          settings: {
            slidesToShow: 2
          }
        }]
    };
    return (
      <div id="client-slider">
        <Slider {...settings}>
            <img src={image1}/>
            <img src={image2}/>
            <img src={image3}/>
            <img src={image4}/>
            <img src={image5}/>
            <img src={image6}/>
            <img src={image7}/>
            <img src={image8}/>
            <img src={image9}/>
            <img src={image10}/>
            <img src={image11}/>
            <img src={image12}/>
            <img src={image13}/>
        </Slider>
      </div>
    );
  };
  
  export default ClientSlider;
