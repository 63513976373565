import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './MilestoneSlider.css';

const MilestoneSlider = () => {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: 20,
        dots: false,
        infinite: false,
        arrows: true,
        focusOnSelect: true,
        responsive: [{
          breakpoint: 998,
          settings: {
            slidesToShow: 2
          }
        }, {
          breakpoint: 520,
          settings: {
            slidesToShow: 1
          }
        }]
    };
    return (
      <div id="milestone-slider">
        <Slider {...settings}>
        <div className='milestone_wrapper'>
            <br/>
            <span className='display-4 milestone_year'>2024</span> <br/><br/>
            <span className='font-16 milestone_project'><b> Gandhi Ashram Precinct</b></span> <br/>
            <span className='milestone_details'> Gandhi Ashram Precinct Development at Sardar Patel Stadium Ward in West Zone, Ahmedabad. </span> <br></br>
            <span className='font-16 milestone_project'><b> Four Lane Road, Dhandhuka</b></span> <br/>
            <span className='milestone_details'> Bagodara - Dhandhuka - Vallabhipur - Bhavnagar Road, Section Fedra to Sarangpur Junction Km. 82/300 to 133/200, Ta.: Dhandhuka, Dist.: Ahmedabad. (Widening from 10 Mtr. to Four Lane)</span>
            <br/><br/>
        </div>
        <div className='milestone_wrapper'>
            <br/>
            <span className='display-4 milestone_year'>2023</span> <br/><br/>
            <span className='font-16 milestone_project'><b> Road Construction, Madhya Pradesh</b></span> <br/>
            <span className='milestone_details'> Upgradation and Reconstruction of Major District Roads in the state of Madhya Pradesh Under NDB - II (Pkg 5) total length 199.13Km . </span><br></br>
            <span className='font-16 milestone_project'><b> Four Lane Road, Sabarkantha</b></span> <br/>
            <span className='milestone_details'>Widening and Strengthening of Naroda - Dehgam - Harsol -Dhansura Road From Km 35+600 to 61+000 (SH 68) (From 10.00 mt Wide Road to Standard Four Lane) In Sabarkantha District. </span>
           <br/><br/>
        </div>
        <div className='milestone_wrapper'>
            <br/>
            <span className='display-4 milestone_year'>2022</span> <br/><br/>
            <span className='font-16 milestone_project'><b> Kheda Dholka Road, Gujarat</b></span> <br/>
            <span className='milestone_details'> Widening of Kheda Dholka Road KM.36/600 to 53/200 widening up to 4 Four Lane, strengthening work, hard side shoulder, cross drainage work and miscellaneous work [27 of 2018 19] </span>
            <br/><br/>
        </div>
        <div className='milestone_wrapper'>
            <br/>
            <span className='display-4 milestone_year'>2021</span> <br/><br/>
            <span className='font-16 milestone_project'><b> Gandhinagar Smart city</b></span> <br/>
            <span className='milestone_details'> Design and Construction of Smart Road of Road No. 6, Road No. 7 & G Road including widening of roads in Gandhinagar City </span>
            <br/><br/>
        </div>
        
        </Slider>
      </div>
    );
  };
  
  export default MilestoneSlider;
