import React, { useState } from 'react';
import logo from './NCC-logo.webp';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Link } from 'react-router-dom';
import './Menubar.css';


export default function Menubar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isProjectsSelected] = useState(window.location.href.indexOf("/project-services") > -1 ? true : false);
  const [expanded, setExpanded] = useState(false);
  
    return (
      <Container >
       <Navbar expand="lg" fixed="top" id="header"  expanded={expanded}>
      <Container >
      <Navbar.Brand href="/">
        <Image src={logo} alt="Nataraj Construction Company logo"  /> 
      </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}  />
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="ml-auto pt-3" >
          <NavLink to="/" activeclassname="active" onClick={() => setExpanded(false)}>HOME</NavLink>
          <NavLink to="/our-company"  activeclassname="active" onClick={() => setExpanded(false)}>OUR COMPANY</NavLink>
          <NavLink to="/project-services/on-going"  activeclassname="active" onClick={() => setExpanded(false)}>PROJECTS & SERVICES</NavLink>
          
            {/* <NavDropdown
                title="Projects & Services"
                id="basic-nav-dropdown"
                show={showDropdown}
                onMouseEnter={() => setShowDropdown(true)}
                onMouseLeave={() => setShowDropdown(false)}
                // onToggle={() => { window.location.href = '/project-services'}}
                to="/project-services"
                as={NavLink}
                activeclassname="active"
                 className={window.location.href.indexOf("/project-services") > -1 ? 'active' : ''}
              >
                <NavLink onClick={() => setExpanded(false)}  to="/project-services/all" activeclassname="active" >All Projects</NavLink>
                <NavLink onClick={() => setExpanded(false)} to="/project-services/roads-highways" activeclassname="active" >Road & Highways</NavLink>
                <NavLink onClick={() => setExpanded(false)} to="/project-services/bridges" activeclassname="active" >Bridges</NavLink>
                <NavLink onClick={() => setExpanded(false)} to="/project-services/awarded" activeclassname="active" >Awarded</NavLink>
                <NavLink onClick={() => setExpanded(false)} to="/project-services/completed" activeclassname="active" >Completed</NavLink>
                <NavLink onClick={() => setExpanded(false)} to="/project-services/on-going" activeclassname="active" >On Hand</NavLink>
                {/* <NavDropdown.Item onClick={() => setExpanded(false)} eventKey={3.1} href="/project-services/all" className={isProjectsSelected && window.location.href.indexOf("/all") > -1 ?  'active' : ''}>All Projects</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} eventKey={3.2} href="/project-services/roads-highways" className={isProjectsSelected && window.location.href.indexOf("/roads-highways") > -1 ?  'active' : ''}>Road & Highways</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} eventKey={3.3} href="/project-services/bridges" className={isProjectsSelected && window.location.href.indexOf("/bridges") > -1 ?  'active' : ''}>Bridges</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} eventKey={3.4} href="/project-services/awarded" className={isProjectsSelected && window.location.href.indexOf("/awarded") > -1 ?  'active' : ''}>Awarded</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} eventKey={3.5} href="/project-services/completed" className={isProjectsSelected && window.location.href.indexOf("/completed") > -1 ?  'active' : ''}>Completed</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setExpanded(false)} eventKey={3.5} href="/project-services/on-going" className={isProjectsSelected && window.location.href.indexOf("/on-going") > -1 ?  'active' : ''}>On Hand</NavDropdown.Item> */}
              {/* </NavDropdown> */} 
     
              <NavLink to="/gallery" onClick={() => setExpanded(false)} activeclassname="active">GALLERY</NavLink>
            <NavLink to="/contact" onClick={() => setExpanded(false)} activeclassname="active"><button id="btn-contact">CONTACT US</button></NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </Container>
    )
  }
  